import React from "react";
import NavButton from "./NavButton";
import {FloatingNavStatus} from "./FloatingNav";
import Logo from "./Logo";
import ContentContainer from "./ContentContainer";
import {useRouter} from "next/router";

interface IProps {
    floatingNavStatus: FloatingNavStatus;
    scroller:any
}

const Header = (props: IProps) => {
    const router = useRouter();

    function checkIfOnRootPage(hashtag?:string) {
       if(router.pathname !== '/'){
           router.push('/' + (hashtag ? hashtag : ''))
       }
    }
    return (
        <nav
            className={
                " fixed top-0 left-0 z-50 hidden h-20 w-full flex-row justify-center bg-brand-black text-white md:block md:flex"
            }
        >
            <ContentContainer
                className={
                    " flex h-full w-full max-w-screen-2xl max-w-screen-xl flex-row items-center justify-between"
                }
            >
                <button
                    aria-label={"Scroll to the top"}
                    className={"flex flex-row  text-left"}
                    onClick={() => {
                        checkIfOnRootPage();
                        props.scroller.scrollTo("topScrollToElement", {
                            duration: 1500,
                            delay: 100,
                            smooth: true,
                        });
                    }}
                >
                    <Logo/>
                </button>

                <div className={" flex h-full h-full flex-row  items-center self-end"}>
                    <NavButton
                        borderColor={"border-brand-black"}
                        floatingNavStatus={props.floatingNavStatus}
                        buttonType={FloatingNavStatus.PACKAGES}
                        onClick={() => {
                            checkIfOnRootPage('#packages');
                            props.scroller.scrollTo("packages", {
                                duration: 1500,
                                delay: 100,
                                smooth: true,
                            });
                        }}
                    >
                        Packages
                    </NavButton>


                    <NavButton
                        borderColor={"border-brand-black"}
                        floatingNavStatus={props.floatingNavStatus}
                        buttonType={FloatingNavStatus.ABOUT}
                        onClick={() => {
                            checkIfOnRootPage('#about');
                            props.scroller.scrollTo("about", {
                                duration: 1500,
                                delay: 100,
                                smooth: true,
                            });
                        }}
                    >
                        About
                    </NavButton>


                    <NavButton
                        borderColor={"border-brand-black"}
                        floatingNavStatus={props.floatingNavStatus}
                        buttonType={FloatingNavStatus.TRAININGS}
                        onClick={() => {
                            checkIfOnRootPage('#trainings');
                            props.scroller.scrollTo("trainings", {
                                duration: 1500,
                                delay: 100,
                                smooth: true,
                            });
                        }}
                    >
                        Trainings
                    </NavButton>





                    <NavButton
                        borderColor={"border-brand-black"}
                        floatingNavStatus={props.floatingNavStatus}
                        buttonType={FloatingNavStatus.CONTACT}
                        onClick={() => {
                            checkIfOnRootPage('#contact');
                            props.scroller.scrollTo("contact", {
                                duration: 1500,
                                delay: 100,
                                smooth: true,

                                offset: -50,
                            });
                        }}
                    >
                        Contact
                    </NavButton>
                    <NavButton
                        borderColor={"border-brand-black "}
                        floatingNavStatus={props.floatingNavStatus}
                        buttonType={FloatingNavStatus.RETRO}
                        onClick={() => {
                            router.push('/airetro')
                        }}
                    >
                            AI Retro 💡
                    </NavButton>
                </div>
            </ContentContainer>
        </nav>
    );
};

export default Header;
